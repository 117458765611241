body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-div{
  min-width: 100vw;
  max-width: 100vw;
}

.mobile-about-top-margin{
  margin-top:15vh;
}

/* Colors */
/* --------------------------- */
.white{
  color:#000;
  background-color:#fff}

.red{
  color:#000000!important;
  background-color:#ECBAA8!important;
}
.blue{
  color:#000000!important;
  background-color:#87CEEB!important;
}
.green{
  color:#000000!important;
  background-color:#ACD39D!important;
}
.yellow-gold{
  color:#fff!important;
  background-color:#F68D2E!important;
}
.purple{
  color:#fff!important;
  background-color:#595478!important;
}
.grey{
  color:#fff!important;
  background-color:#8e8c8f!important;
}
/* color classes-- hidden writing */
.solid-red{
  color:#ECBAA8;
  background-color:#ECBAA8;
}
.solid-blue{
  color:#87CEEB!important;
  background-color:#87CEEB!important;
}
.solid-green{
  color:#ACD39D!important;
  background-color:#ACD39D!important;
}
.solid-yellow-gold{
  color:#F68D2E!important;
  background-color:#F68D2E!important;
}
.solid-purple{
  color:#595478!important;
  background-color:#595478!important;
}
.solid-grey{
  color:#8e8c8f!important;
  background-color:#8e8c8f!important;
}
/* Containers */

/* .large-centered-container{
   width:95vw;
   padding:2.5vw;
   margin-left:2.5vw;

} */
/* --------------------------- */
/* Rows */
/* --------------------------- */
.row-extra-small{
  height:3.5vh;
}
.row-small{
  height:7.5vh;
}
.row-medium{
  height:12.5vh;
}
.row-large:{
  height:15vh;
}
/* Columns */
/* --------------------------- */
.col-small{
  height:5vh;
}
.col-medium{
  height:12.5vh;
}
.col-large{
  height:15vh;
}

.col-1{
  min-width:1vw;
  max-width: 1vw;
}
.col-2{
  min-width:1vw;
  max-width: 1vw;
}

.col-3{
  min-width:3vw;
  max-width: 3vw;
}
.col-5{
  min-width:5vw;
  max-width: 5vw;
}
.col-10{
  min-width:10vw;
  max-width: 10vw;
}
.col-12.5{
  min-width:12.5vw;
  max-width: 12.5vw;
}
.col-15{
  min-width:15vw;
  max-width: 15vw;
}
.col-20{
  min-width:20vw;
  max-width: 20vw;
}
.col-25{
  min-width:25vw;
  max-width: 25vw;
}
.col-30{
  min-width:30vw;
  max-width: 30vw;
}
.col-36{
  min-width:36vw;
  max-width: 36vw;
}
.col-37{
  min-width:37vw;
  max-width: 37vw;
}
.col-38{
  min-width:38vw;
  max-width: 38vw;
}
.col-39{
  min-width:39vw;
  max-width: 39vw;
}
.col-40{
  min-width:40vw;
  max-width: 40vw;
}
.col-44{
  min-width:44vw;
  max-width: 44vw;
}
.col-45{
  min-width:45vw;
  max-width: 45vw;
}
.col-48{
  min-width:48vw;
  max-width: 48vw;
}
.col-49{
  min-width:49vw;
  max-width: 49vw;
}
.col-50{
  min-width:50vw;
  max-width: 50vw;
}
.col-52{
  min-width:52vw;
  max-width: 52vw;
}
.col-53{
  min-width:53vw;
  max-width: 53vw;
}
.col-54{
  min-width:54vw;
  max-width: 54vw;
}
.col-57{
  min-width:57vw;
  max-width: 57vw;
}
.col-59{
  min-width:59vw;
  max-width: 59vw;
}
.col-60{
  min-width:60vw;
  max-width: 60vw;
}
.col-70{
  min-width:70vw;
  max-width: 70vw;
}
.col-75{
  min-width:75vw;
  max-width: 75vw;
}
.col-80{
  min-width:80vw;
  max-width: 80vw;

}
.col-90{
  min-width:90vw;
  max-width: 90vw;
}

.col-100{
  min-width:100vw;
  max-width: 100vw;
}
/* Image sizes */
/* --------------------------- */
.square-icon-small{
  max-height:5vh;
  max-width:5vh;
}
.square-icon-medium{
  max-height:5vh;
  max-width:5vh;
}
.square-icon-large{
  max-height:10vh;
  max-width:10vh;
}
.rectangular-icon-small{
  max-height:7.5vh;
  width:15vh;

}
.rectangular-icon-medium{
  max-height:10vh;
  width:20vh;
}
.rectangular-icon-large{
  max-height:15vh;
  width:30vh;
}

/* Alignments */
/* --------------------------- */
.centered-children{
  display: flex;
      justify-content: center;
      align-items: center;
}
.horizontal-centered-children{
  display: flex;
      justify-content: center;
      align-items: center;
}
.vertical-centered-children{
  display: flex;
   align-items: center;
}
.on-bottom{
  position: absolute;
  bottom: 0;
}
.on-bottom-padded{
  position: absolute;
  bottom: 2.5vh;
}
.on-bottom-left{
  position: absolute;
  bottom: 0;
  left:0;
}
.on-bottom-right{
  position: absolute;
  bottom: 0;
  right:0;
}
.on-left{
  position: absolute;
  left:0;
}
.on-top-right{
  position: absolute;
  top: 0;
  right:0;
}
.vertical-center{
  top: 50%;
transform: translateY(-50%);
-ms-transform: translateY(-50%);
-moz-transform: translateY(-50%);
-webkit-transform: translateY(-50%);
-o-transform: translateY(-50%);
}

.top-left {
  position: absolute;
  top: 2vh;
  left: 2.5vw;
}
.top-left-mobile {
  position: absolute;
  top: 1vh;
  left: 3vw;
}
/* Font sizes */
/* --------------------------- */

.small-centered-font{
  font-size: .75rem;
  align-self: center;
}
.full-justified-text{
  text-align: justify;
}
.justified-text{
  text-align: left;
}
.justified-text-right{
  text-align: right;
}

/* Margins and padding */
.margin-bottom-small{
  margin-bottom:2.5vh;
}
.margin-left-small{
  margin-left:3vw;
}
.margin-right-small{
  padding-right:1vw;
}
.margin-right-medium{
  padding-right:2.5vw;
}
.padding-top-extra-small{
  padding-top:1vh;
}
.padding-top-small{
  padding-top:2.5vh;
}

.padding-bottom-small{
  padding-bottom:2.5vh;
}
.padding-bottom-medium{
  padding-bottom:7.5vh;
}



/* Custom input styles */

/* ----------header---------- */
.medium-landscape-header-input{
  max-width:35vw;
   max-height:4.5vh;
   border: .2vmax solid!important;
   border-radius: .3vmax!important;
}
.medium-landscape-header-input-col{
  max-width:35vw;
   max-height:4.5vh;
   margin-left:3vw;
}
.medium-landscape-header-input-row{
  width:100vw;
  height:5vh;
}
.mobile-header-input{
  max-width:55vw;
   max-height:3vh;

}
.mobile-header-input-col{
  max-width:55vw;
   max-height:4vh;
   margin-left:6vw;
}
.mobile-header-input-row{
  width:100vw;
  height:4vh;
}
/* ----------Title---------- */
.medium-landscape-title-input{
  max-width:17vw;
   max-height:4.5vh;
}
.medium-landscape-title-input-col{
  max-width:19vw;
  min-width:19vw;
   max-height:4.5vh;
   margin-top:2.5vh;
}
.mobile-title-input{
  max-width:22vw;
   max-height:2.5vh;
   min-height:2.5vh;
}
.mobile-title-input-col{
   max-height:5vh;
   min-height:5vh;
}
.mobile-title-input-row{
  width:100vw;
  height:5vh;
  margin-left: 2.5vw;
}

/* ----------Body--------- */
.medium-landscape-body-input{
  max-width:17vw;
  min-width:17vw;
   max-height:4.5vh;
   border: .2vmax solid!important;
   border-radius: .3vmax!important;
}
.medium-landscape-body-input-col{
  max-width:19vw;
  min-width:19vw;
  max-height:4.5vh;
}
.mobile-body-input{
  max-height:2.5vh;
  min-height:2.5vh;
}
.mobile-body-input-col{
  max-height:2.5vh;
}

/* --------Cost--------- */
.medium-landscape-cost-input{
   max-width:18vw;
   min-width:18vw;
   max-height:4.5vh;
   border: .2vmax solid!important;
   border-radius: .3vmax!important;
}
.medium-landscape-cost-input-col{
  max-width:20vw;
  max-height:4.5vh;
}
.medium-landscape-cost-input-row{
  width:100vw;
  height:5vh;
}

.mobile-cost-input{
   max-width:20vw;
   min-width:20vw;
   max-height:4vh;
}
.mobile-cost-input-col{
  max-width:22.5vw;
  max-height:4vh;
}
.mobile-cost-input-row{
  width:100vw;
  height:4vh;
}


.footerStyle{
  height:10vw;
   width:80vw;
   margin-left:4vw;
   border: .2vmax solid!important;
   border-radius: .3vmax!important;
}




/* -------------------------------------------------------------------------- */
/* ---------------------- */
/* Invoice Builder Styles */
/* ---------------------- */
/* -------------------------------------------------------------------------- */

/* ---------------------- */
/* Container Component */
/* ---------------------- */











/* Standard Laptop Styles */
.publicHeader{
  height: 10vh;
  padding: 2.5vw;
  background-color:white;
}
/* Mobile Styles */
























.iconCol{
  max-width:5vw;
}
.iconCol_Mobile{
  max-width:5vw;
}
.loginButton{
  max-width:10vw;

}
.loginButton_mobile{
  max-width:15vw;
}
.signupButton{
  max-width:10vw;


}
.signupButton_mobile{
  max-width:15vw;
}
.headerLogo{
  width:10vw;
  float:left;

}
.headerLogo_mobile{
  width:20vw;
  float:left;
}
.headerLogoCol{
min-width: 80%
}
.headerLogoCol_mobile{
min-width: 60%
}

.headerLoginCol{
min-width: 10%
}
.headerLoginCol_mobile{
min-width: 20%
}
.headerSignupCol{
min-width: 10%
}
.headerSignupCol_mobile{
min-width: 20%
}









.modal-90w{
  width:90vw;
}
.xl-modal {
    width: 90vw;
    max-width: 90vw;
}


  .invoice_input::placeholder{
    opacity: 1!important;
    }
.title_input{
  padding: none!important;
  margin-top: -2.5vh!important;
    /* margin: none!important; */

}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.show{
    display: '';
}
.hide{
  display: none;
}

@media only screen
  and (orientation : landscape) {

    .workspace_container{
      width:100vw;
      /* min-width: 97vw;
      max-width:99vw; */
      /* height:120vh; */
      overflow-y:scroll;
      overflow-x:hidden;
      margin-top: 5vh;
      /* margin-bottom: 5vh; */
    }

    .workspace_col{


      padding: 3.1vh 3.1vh 3.1vh 3.1vh;

    }
    .workspace_tile{

      width:20vw;
      height:calc(20vw + 7.5vh);
    }
    .workspace_img{
      width:20vw;
      height:20vw;
      padding:0;

    }
    .workspace_picture{
      width:20vw;
      height:20vw;
      min-width:20vw;
      min-height:20vw;
    }
    .workspace_display_name{
      border:none;
      font-size: 2.3vh;
      text-align:left;
      margin-top:2vh;
      width:12vw;
      height:3.5vh;
    }
    .workspace_display_name_div{
      height:7.5vh;
    }
    .workspace_icon_div{

      width:1.2vw;
      height:2.25vh;
      /* margin-top:2.4vh; */
      /* margin-right:1.5vh; */


    }
    .workspace_icon{

      max-width:1.2vw;
      max-height:2.25vh;
      margin-top:2.5vh;
      /* margin-right:1.5vh; */


    }
  }
  @media only screen
  and (orientation : portrait) {

    .workspace_container{
      width:95vw;
      height:115vw;
      max-height:77vh;
      overflow-y:scroll;
      overflow-x:hidden;
      margin-top: 5vh;
    }
    .workspace_col{


      padding: 3.1vmin 3.1vmin 3.1vmin 3.1vmin
    }
  }
/* .modal-huge{
  min-width: 90vw!important;
}
.modal-large {
  min-width: 65vw!important;
}
.modal-medium {
  min-width: 50vw!important;
}
.medium_modal_input{
  min-width: 25vw!important;
  position: relative;
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
} */
/* .medium_modal_input_label{

  margin: auto;
  position: relative;
  left: 25%;
  -ms-transform: translateX(-25%);
  transform: translateX(-25%);
} */

.contact_modal_input{
  min-width: 20vw!important;
  position: relative;
  left: 25%;
  -ms-transform: translateX(-25%);
  transform: translateX(-25%);
}
.contact_modal_input_label{

  margin: auto;
  position: relative;
  left: 15%;
  -ms-transform: translateX(-15%);
  transform: translateX(-15%);
}

.input-form{
  display:flex;
  /* flex-direction: column-reverse; */
  flex-direction: column;
}

.input-form {
transition: 0.6s ease;
}

/* .input-form label{
  transition: 0.6s ease;
  cursor: text;
  transform-origin: left top;
  color: #757575;
  position: absolute;
} */


/* .input-form input:not(:placeholder-shown)~label,
.input-form input:focus ~ label{
  transform: translate(-3.5vmin, -2.5vmin) scale(0.8);
  color: black;
} */
.input-form-field{


  padding: 1.5vmin 1.5vmin .25vmin 1.5vmin;
  border: none;
  /* border-bottom: .2vmin solid #9e9e9e9e; */
  outline: none;
  /* background-color: #f5f5f5; */
}

.input-form-button{

  font-size: 1.2em;
  border: none;
  /* border-bottom: .2vmin solid #9e9e9e9e; */
  outline: none;
}
.input-form input::placeholder{
  opacity: 0;
}

.no-borders{
  border: none!important;
}
.show_placeholder::placeholder{
  opacity: 1!important;
}


.nav-bar-styles{
  flex:1;
  height:15vh;
}
.nav-bar-link{
  flex:1;
  align-items: stretch;

}
.selected_nav_bar,.nav_button:hover, .cactus{
  color: #4D774E!important;
  background-color:#FFFFFF!important;
}
.goal_add_buttons,    .nice-button,.login-button,.login-register-buttons,.main-color-dark {
  background-color: #46624B;
  /* From cactus */
   /* background-color:#164A41; */
   color:#FFFFFF;
 }
  .main-color-med {
  background-color:#4D774E;
  color:#FFFFFF;
}

.nav-bar-styles,.accent-color-1 {
  /* background-color: #f3d250; */
  color:#F1B24A!important;
  background-color:#FFFFFF;
}

.tile-pic{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .client-tile{
      width:20vw;
      height:20vw + 7.5vh;

    }

    .client-image{
      width:20vw;
      height:20vw;
    }

    .client_display_name{
      border:none;
      font-size: 2.3vh;
      text-align:left;
      margin-top:2vh;
      width:12vw;
      height:3.5vh;
    }
    .client_display_name_div{
      height:7.5vh;
    }

    .project-tile{
      width:20vw;
      height:20vw + 7.5vh;

    }

    .project-image{
      width:20vw;
      height:20vw;
    }

    .project_display_name{
      border:none;
      font-size: 2.3vh;
      text-align:left;
      margin-top:2vh;
      width:12vw;
      height:3.5vh;
    }
    .project_display_name_div{
      height:7.5vh;
    }




    .contact-tile{
      width:20vw;
      height:20vw + 7.5vh;

    }

    .contact-image{
      width:20vw;
      height:20vw;
    }

    .contact_display_name{
      border:none;
      font-size: 2.3vh;
      text-align:left;
      margin-top:2vh;
      width:12vw;
      height:3.5vh;
    }
    .contact_display_name_div{
      height:7.5vh;
      /* background-color: red; */
    }





    .login-button{
        width:10vw;
        height:8vh;
        font-size: 1.8vmin;
        transition: .6s ease;
        text-align:center;
        cursor:pointer;
        border:none!important;
        /* background-color:#5DA2D5; */
        /* color:white; */
      }

    .client-col{
      padding: 3.1vmin 3.1vmin 3.1vmin 3.1vmin;
    }
    .project-col{
      padding: 3.1vmin 3.1vmin 3.1vmin 3.1vmin;
    }
    .contact-col{
      padding: 3.1vmin 3.1vmin 3.1vmin 3.1vmin;
    }
    .grid-row{
      text-align:center;
      margin:0 auto;
    }
    .grid-col{
      display:inline-block;
      vertical-align: middle;
      float: none;
    }
      .nav_button{
        width:12vmin;
        height:6vmin;
        font-size: 2.2vmin;
        transition: .2s ease;
        cursor:pointer;
        border:none;
        /* background-color:#f5f5f5; */
        color:black;
        /* margin:auto; */
      }
      .nav_button:hover {
        /* background-color: #90CCf4; */
        color: black;
        font-size: 2.2vmin;
        transition: .2s ease;
        width:12vmin;
        height:6vmin;
        /* margin:auto; */
      }
      .add_new_button{
        width:17vmin;
        height:7vmin;
        font-size: 2.6vmin;
        transition: .4s ease;
        cursor:pointer;
        border:none;
        background-color: #050505;
        color:#f5f5f5;
        vertical-align: middle;
      }




    .sign_out_button{
      width:5vw;
      height:5.5vh;
      font-size: .85vw;
      transition: .4s ease;
      cursor:pointer;
      border:none;
      background-color: #050505;
      color:#f5f5f5;
      margin-left:3vw;
      max-width:16vmin;
       max-height:8vmin;

    }
    .workspace_header{
      width:auto;
      height:12vh;
    }

    .new_workspace_button:hover{
      font-size: 4.7vw;
      width:20vw;
      height:20vw;
      border-style: none;
          transition: .3s ease;
    }
    .new_workspace_button{
      /* background-color: #46624B; */
      width:20vw;
      height:20vw;
      font-size: 3.2vw;
      transition: .3s ease;
      border-style: none;
       color:black;
    }





    /* .medium_modal_input_label */
    /* .w3-input,.medium_modal_inputsform, p,modal-content,.register-container,.input-form-field,.login-container,.inner_header, .workspace_header,  .accent-color-2 {
      background-color:#FFFFFF!important;
      color:black!important;
    } */

    .nav_button{
      background-color:#FFFFFF;
      color:black;
    }

      .nice-button{
        width:14vw;
        height:8vh;
        font-size: 1.9vmin;
        transition: .4s ease;
        text-align:center;
        cursor:pointer;
        border:none;
        display:inline-block;
        vertical-align:middle;
        border-radius: 4px;

      }






.client_breakdown_row{

          width:90vw;
          height:40vh;
      }
/* Landscape styles */
@media only screen
and (orientation : landscape) {
  .login-container{
    width:35vw;
    height:66vh;
    /* margin-top:55vh; */
    /* margin-left:7.5vw; */
    margin:auto;
    min-height: 28vmax;
  }
  .register-container{
    width:40vw;
    height:75vh;
    margin:auto;
  }
  .welcome-message{
    font-size: 1.7vw;
    width:30vw;
  }
  .welcome-blurb{
    font-size: 1.7vw;
    width:20vw;
  }
  .action-button{
    width:10vw;
    height:8vh;
    font-size: 1.8vmin;
    transition: .6s ease;
    text-align:center;
    cursor:pointer;
    border:none;
    background-color:#5DA2D5;
    color:white;
  }
  /* .action-button:hover {
    width:12vw;
    height:9vh;
  } */
  .login-register-buttons{
    width: 50vw;
    margin:auto;
  }

  .value-prop-square{
    height:15vh;
    border-radius: .6vmin;
    margin:0.3vmin;

  }
  .about-button{
    width:10vw;
    height:8vh;
    margin-top:3vmin;
    margin-left:8vmin;
    font-size: 1.8vmin;
    transition: .6s ease;
    border-radius:.6vmin;
    text-align:center;
    cursor:pointer;
    border:none;
    background-color:#5DA2D5;
    color:white;
  }
  .about-button:hover {
    width:12vw;
    height:9vh;
  }
  .menu-text{
    color:black;
    font-family:'Clarkson';
    font-size:calc(12px + 0.7vw);
    cursor:pointer;
  }
  .menu-col-1{
    margin-top:5.5vh;
    width:16vw!important;
  }
  .menu-col-2{
    margin-top:5.5vh;
    width:18vw!important;
  }
  .menu-col-3{
    margin-top:5.5vh;
    width:16vw!important;
  }

}

/* Portrait styles */
@media only screen
and (orientation : portrait) {
  .login-container{
    width:66vw;
    height:66vh;
    margin:auto;
  }
  .welcome-message{
    font-size: 3.5vw;
    width:60vw;
  }
  .welcome-blurb{
    font-size: 2.8vw;
    width:63vw;
  }
  .action-button{
    width:7vw;
    height:6vh;
    font-size: 1.2vmin;
    transition: .4s ease;
    text-align:center;
    cursor:pointer;
    border:none;
    display:inline-block;
    vertical-align:middle;
    background-color:#5DA2D5;
    color:white;
  }
  .action-button:hover {
    background-color: #ECECEC;
    color: black;
  }

  .login-register-buttons{
    width: 66vw;
    margin:auto;
  }
  .menu-text{
    color:black;
    font-family:'Clarkson';
    font-size:calc(14px + 0.9vw);
    cursor:pointer;

  }
  .menu-logo{
    width:15vw;
  }
  .menu-col-1{
    margin-top:5.5vh;
    width:23vw!important;
  }
  .menu-col-2{
    margin-top:5.5vh;
    width:29vw!important;
  }
  .menu-col-3{
    margin-top:5.5vh;
    width:23vw!important;
  }
}



.center-over-fullscreen-div{
  margin:auto;

}

.value-prop-blurb{

  font-size:3.4vmin;
  padding-top: 4.5vh;
}



@media only screen
and (orientation : landscape) {
.login-register-field{
  width:25vw;
}
.login-register-label{
  align:left;
  margin-left:5.5vw;
}
}

@media only screen
and (orientation : portrait) {
.login-register-field{
  width:50vw;
}
.login-register-label{
  align:left;
  margin-left:5vw;
}
}



.modal-large {
  width: 65vw;
}
.modal-medium {
  width: 40vw;
}

.modal {

  width:100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.6);



}
.fade {

  width:100%;
  height: 150%;
  background: rgba(0, 0, 0, 0.6);


}


.display-block {
  display: block;
}

.display-none {
  display: none;
}




ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

li {
  float: left;
}

li a {
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
}

li a:hover {
  background-color: #111;
}



.always-to-front{
   z-index: 99;
}

.transparent{
  opacity: 0.5!important;
}

.blue-text{
  color: #326ba8;
}
.header-container {
  height: 130px;
  position:relative;
}
.body-container {
  height: 60%;
  width: 30%;
  position: absolute;
}
.footer-container{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: white;
  height:5%;
  text-align: center;

}
.background-container {
  height: 100%;
  width: 100%;
  position: absolute;
}
.blur{

}


.align-right{
  position: absolute;
  left: 90%;

}
.center {
  text-align: center;
}
.navbar-fixed-top {
position: fixed;
right: 10;
left: 0;
z-index: 999;
display:inline-block;
position:relative;
}

.third{
  width:33.33333%
}
.col{
  padding:0 8px
}

.active{

}


.shaded{
  box-shadow:0 2px 5px 0 rgba(0,0,0,0.2),0 2px 5px 0 rgba(0,0,0,0.19)
}
.shaded-light{
  box-shadow:0 1px 3px 0 rgba(0,0,0,0.2),0 1px 4px 0 rgba(0,0,0,0.19)
}
.shadow{
   color:#000!important;background-color:#edebeb!important
}
.shadow-hover:hover{
    box-shadow:0 2px 5px 0 rgba(0,0,0,0.2),0 2px 5px 0 rgba(0,0,0,0.19)
}

.nice-grey{
  background-color:#ECECEC;

}
.nice-yellow{
  background-color:#f3d250;
}
.nice-light-blue{
  background-color:#90CCf4;
}

.nice-dark-blue{
  background-color:#5DA2D5;
  color:white;
}
.nice-red{
  background-color:#F78888;
}
.nice-pink-white{
  background-color:#f5f5f5!important;
}
.nice-green{
  background-color:#ACD39D;
}
.nice-purple{
  background-color:#d8a6ed;
}

.balanced-light-green{
  background-color:#7fc3b0;
}
.balanced-dark-green{
  background-color:#40b3a1;
}
.off-white{
  background-color:#DEF2F1;
}
.cool-fresh-1{
  background-color:#05386B;
}
.cool-fresh-2{
  background-color:#379683;
}
.cool-fresh-3{
  background-color:#5CDB95;
}
.cool-fresh-4{
  background-color:#8EE4AF;
}
.cool-fresh-5{
  background-color:#EDF5E1;
}


.dark-sea-green{
  background-color:#97C497;
}



.calming-1{
  background-color:#BBE9DB;
}
.calming-2{
  background-color:#9FE1CD;
}
.calming-3{
  background-color:#7FD7BD;
}
.calming-4{
  background-color:#43C59E;
}
.calming-5{
  background-color:#EFFAFB;
}
.calming-6{
  background-color:#48BEFF;
}
.calming-7{
  background-color:#368FC0;
}
.calming-8{
  background-color:#1F506D;
}

.contrast-1{
  background-color:#19181A;
}
.contrast-2{
  background-color:#479761;
}
.contrast-3{
  background-color:#86C232;
}



/* Landscape styles */
@media only screen
and (orientation : landscape) {
  .login-container{
    width:40vw;
    height:66vh;
    margin:auto;
  }
  .welcome-message{
    font-size: 1.7vw;
    width:30vw;
  }
  .welcome-blurb{
    font-size: 1.7vw;
    width:20vw;
  }
  .action-button{
    width:10vw;
    height:8vh;
    font-size: 1.8vmin;
    transition: .6s ease;
    text-align:center;
    position: absolute;
    cursor:pointer;
    border:none;
    margin: auto;
    background-color:#f5f5f5;
    color:black;
  }
  /* .action-button:hover {
    width:12vw;
    height:9vh;
  } */
  .login-register-buttons{
    width: 50vw;
    margin:auto;
  }

  .value-prop-square{
    height:15vh;
    /* border: 1vw #f5f5f5; */
    border-radius: .6vmin;
    margin:0.3vmin;
    /* width:24.9vw; */

  }
  .menu-text{
    color:black;
    font-family:'Arial';
    font-size:calc(12px + 0.7vw);
    cursor:pointer;
    margin-top:3.5vh;
    transition: 0.2s ease;
    font-weight:500;
  }
  .menu-text:hover{
    color:black;
    font-family:'Arial';
    font-size:calc(12px + 0.9vw);
    cursor:pointer;
    margin-top:3.5vh;
    font-weight:500;
  }
}

/* Portrait styles */
@media only screen
and (orientation : portrait) {
  .login-container{
    width:66vw;
    height:66vh;
    margin:auto;
  }
  .welcome-message{
    font-size: 3.5vw;
    width:60vw;
  }
  .welcome-blurb{
    font-size: 2.8vw;
    width:63vw;
  }
  .action-button{
    width:7vw;
    height:6vh;
    font-size: 1.2vmin;
    transition: .4s ease;
    text-align:center;
    cursor:pointer;
    border:none;
    vertical-align:middle;
    background-color:#5DA2D5;
    color:white;
  }
  /* .action-button:hover {
    background-color: #ECECEC;
    color: black;
  } */

  .login-register-buttons{
    width: 66vw;
    margin:auto;
  }
}

/* General styles */
.feature-blurb{
  color:black;
  font-family:'Arial';
  font-size:calc(12px + 1.7vw);
  text-align: center;
  cursor:pointer;
  /* margin-top:3.5vh; */
  transition: 0.2s ease;
  font-weight:500;
}
.large-icon{
  height:15vmin!important;
  width:15vmin!important;
  transition: .6s ease;
}
.small-icon{
  height:12vmin;
  width:12vmin;
  transition: .6s ease;
}

.center-over-fullscreen-div{
  margin:auto;
}

.value-prop-blurb{

  font-size:3.4vmin;
  /* padding-top: 4.5vh; */
}
.feature_icon{
  z-index:99;
}
.white_filter {
filter: invert(99%) sepia(1%) saturate(44%) hue-rotate(12deg) brightness(114%) contrast(85%); }
.nice-grey{
  background-color:#ECECEC;

}
.nice-yellow{
  background-color:#f3d250;
}
.nice-light-blue{
  background-color:#90CCf4;
}

.nice-dark-blue{
  background-color:#5DA2D5;
  color:white;
}
.nice-red{
  background-color:#F78888;
}
.nice-pink-white{
  background-color:#f5f5f5;
}
.nice-green{
  background-color:#ACD39D;
}
.nice-purple{
  background-color:#d8a6ed;
}
.nice-pink{
  background-color: #917174
}

.balanced-light-green{
  background-color:#7fc3b0;
}
.balanced-dark-green{
  background-color:#40b3a1;
}
.off-white{
  background-color:#DEF2F1;
}
.cool-fresh-1{
  background-color:#05386B;
}
.cool-fresh-2{
  background-color:#379683;
}
.cool-fresh-3{
  background-color:#5CDB95;
}
.cool-fresh-4{
  background-color:#8EE4AF;
}
.cool-fresh-5{
  background-color:#EDF5E1;
}


.dark-sea-green{
  background-color:#97C497;
}



.calming-1{
  background-color:#BBE9DB;
}
.calming-2{
  background-color:#9FE1CD;
}
.calming-3{
  background-color:#7FD7BD;
}
.calming-4{
  background-color:#43C59E;
}
.calming-5{
  background-color:#EFFAFB;
}
.calming-6{
  background-color:#48BEFF;
}
.calming-7{
  background-color:#368FC0;
}
.calming-8{
  background-color:#1F506D;
}

.contrast-1{
  background-color:#19181A;
}
.contrast-2{
  background-color:#479761;
}
.contrast-3{
  background-color:#86C232;
}



.cactus-dark-green{
  background-color:#164A41;
}
.cactus-medium-green{
  background-color:#4D774E;
}
.cactus-light-green{
  background-color:#9DC88D;
}
.cactus-gold{
  background-color:#F1B24A;
}
.cactus-white{
  background-color:#FFFFFF;
}




























/* W3.CSS 4.13 June 2019 by Jan Egil and Borge Refsnes */
html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html{-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section{display:block}summary{display:list-item}
audio,canvas,progress,video{display:inline-block}progress{vertical-align:baseline}
audio:not([controls]){display:none;height:0}[hidden],template{display:none}
a{background-color:transparent}a:active,a:hover{outline-width:0}
abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}
b,strong{font-weight:bolder}dfn{font-style:italic}mark{background:#ff0;color:#000}
small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-0.25em}sup{top:-0.5em}figure{margin:1em 40px}img{border-style:none}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}hr{box-sizing:content-box;height:0;overflow:visible}
button,select,textarea,optgroup{font:inherit;margin:0}optgroup{font-weight:bold}
button{overflow:visible}button,select{text-transform:none}
button,[type=button],[type=reset],[type=submit]{-webkit-appearance:button}
button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{border-style:none;padding:0}
button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring{outline:1px dotted ButtonText}
fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:.35em .625em .75em}
legend{color:inherit;display:table;max-width:100%;padding:0;white-space:normal}textarea{overflow:auto}


[type=checkbox],[type=radio]{padding:0}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px}
[type=search]::-webkit-search-decoration{-webkit-appearance:none}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
/* End extract */
html,body{font-family:Verdana,sans-serif;font-size:15px;line-height:1.5}html{overflow-x:hidden}



h1{font-size:36px}h2{font-size:30px}h3{font-size:24px}h4{font-size:20px}h5{font-size:18px}h6{font-size:16px}.w3-serif{font-family:serif}
h1,h2,h3,h4,h5,h6{font-family:"Segoe UI",Arial,sans-serif;font-weight:400;margin:10px 0}.w3-wide{letter-spacing:4px}
hr{border:0;border-top:1px solid #eee;margin:20px 0}
.w3-image{max-width:100%;height:auto}img{vertical-align:middle}a{color:inherit}
.w3-table,.w3-table-all{border-collapse:collapse;border-spacing:0;width:100%;display:table}.w3-table-all{border:1px solid #ccc}
.w3-bordered tr,.w3-table-all tr{border-bottom:1px solid #ddd}.w3-striped tbody tr:nth-child(even){background-color:#f1f1f1}
.w3-table-all tr:nth-child(odd){background-color:#fff}.w3-table-all tr:nth-child(even){background-color:#f1f1f1}
.w3-hoverable tbody tr:hover,.w3-ul.w3-hoverable li:hover{background-color:#ccc}.w3-centered tr th,.w3-centered tr td{text-align:center}
.w3-table td,.w3-table th,.w3-table-all td,.w3-table-all th{padding:8px 8px;display:table-cell;text-align:left;vertical-align:top}
.w3-table th:first-child,.w3-table td:first-child,.w3-table-all th:first-child,.w3-table-all td:first-child{padding-left:16px}


.w3-btn,.w3-button{border:none;display:inline-block;padding:8px 16px;vertical-align:middle;overflow:hidden;text-decoration:none;color:inherit;background-color:inherit;text-align:center;cursor:pointer;white-space:nowrap}
.w3-btn:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)}
.w3-btn,.w3-button{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.w3-disabled,.w3-btn:disabled,.w3-button:disabled{cursor:not-allowed;opacity:0.3}.w3-disabled *,:disabled *{pointer-events:none}
.w3-btn.w3-disabled:hover,.w3-btn:disabled:hover{box-shadow:none}
.w3-badge,.w3-tag{background-color:#000;color:#fff;display:inline-block;padding-left:8px;padding-right:8px;text-align:center}.w3-badge{border-radius:50%}
.w3-ul{list-style-type:none;padding:0;margin:0}.w3-ul li{padding:8px 16px;border-bottom:1px solid #ddd}.w3-ul li:last-child{border-bottom:none}
.w3-tooltip,.w3-display-container{position:relative}.w3-tooltip .w3-text{display:none}.w3-tooltip:hover .w3-text{display:inline-block}
.w3-ripple:active{opacity:0.5}.w3-ripple{transition:opacity 0s}
.w3-input{padding:8px;display:block;border:none;border-bottom:1px solid #ccc;width:100%}
.w3-select{padding:9px 0;width:100%;border:none;border-bottom:1px solid #ccc}
.w3-dropdown-click,.w3-dropdown-hover{position:relative;display:inline-block;cursor:pointer}
.w3-dropdown-hover:hover .w3-dropdown-content{display:block}
.w3-dropdown-click:hover{background-color:#ccc;color:#000}
.w3-dropdown-hover:hover > .w3-button:first-child,.w3-dropdown-click:hover > .w3-button:first-child{background-color:#ccc;color:#000}
.w3-dropdown-content{cursor:auto;color:#000;background-color:#fff;display:none;position:absolute;min-width:160px;margin:0;padding:0;z-index:1}
.w3-check,.w3-radio{width:24px;height:24px;position:relative;top:6px}
.w3-sidebar{height:100%;width:200px;background-color:#fff;position:fixed!important;z-index:1;overflow:auto}
.w3-bar-block .w3-dropdown-hover,.w3-bar-block .w3-dropdown-click{width:100%}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,.w3-bar-block .w3-dropdown-click .w3-dropdown-content{min-width:100%}
.w3-bar-block .w3-dropdown-hover .w3-button,.w3-bar-block .w3-dropdown-click .w3-button{width:100%;text-align:left;padding:8px 16px}
.w3-main,#main{transition:margin-left .4s}
.w3-modal{z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.w3-modal-content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;width:600px}
.w3-bar{width:100%;overflow:hidden}.w3-center .w3-bar{display:inline-block;width:auto}
.w3-bar .w3-bar-item{padding:8px 16px;float:left;width:auto;border:none;display:block;outline:0}
.w3-bar .w3-dropdown-hover,.w3-bar .w3-dropdown-click{position:static;float:left}
.w3-bar .w3-button{white-space:normal}
.w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
.w3-responsive{display:block;overflow-x:auto}
.w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
.w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
.w3-col,.w3-half,.w3-third,.w3-twothird,.w3-threequarter,.w3-quarter{float:left;width:100%}
.w3-col.s1{width:8.33333%}.w3-col.s2{width:16.66666%}.w3-col.s3{width:24.99999%}.w3-col.s4{width:33.33333%}
.w3-col.s5{width:41.66666%}.w3-col.s6{width:49.99999%}.w3-col.s7{width:58.33333%}.w3-col.s8{width:66.66666%}
.w3-col.s9{width:74.99999%}.w3-col.s10{width:83.33333%}.w3-col.s11{width:91.66666%}.w3-col.s12{width:99.99999%}
@media (min-width:601px){.w3-col.m1{width:8.33333%}.w3-col.m2{width:16.66666%}.w3-col.m3,.w3-quarter{width:24.99999%}.w3-col.m4,.w3-third{width:33.33333%}
.w3-col.m5{width:41.66666%}.w3-col.m6,.w3-half{width:49.99999%}.w3-col.m7{width:58.33333%}.w3-col.m8,.w3-twothird{width:66.66666%}
.w3-col.m9,.w3-threequarter{width:74.99999%}.w3-col.m10{width:83.33333%}.w3-col.m11{width:91.66666%}.w3-col.m12{width:99.99999%}}
@media (min-width:993px){.w3-col.l1{width:8.33333%}.w3-col.l2{width:16.66666%}.w3-col.l3{width:24.99999%}.w3-col.l4{width:33.33333%}
.w3-col.l5{width:41.66666%}.w3-col.l6{width:49.99999%}.w3-col.l7{width:58.33333%}.w3-col.l8{width:66.66666%}
.w3-col.l9{width:74.99999%}.w3-col.l10{width:83.33333%}.w3-col.l11{width:91.66666%}.w3-col.l12{width:99.99999%}}
.w3-rest{overflow:hidden}.w3-stretch{margin-left:-16px;margin-right:-16px}
.w3-content,.w3-auto{margin-left:auto;margin-right:auto}.w3-content{max-width:980px}.w3-auto{max-width:1140px}
.w3-cell-row{display:table;width:100%}.w3-cell{display:table-cell}
.w3-cell-top{vertical-align:top}.w3-cell-middle{vertical-align:middle}.w3-cell-bottom{vertical-align:bottom}
.w3-hide{display:none!important}.w3-show-block,.w3-show{display:block!important}.w3-show-inline-block{display:inline-block!important}
@media (max-width:1205px){.w3-auto{max-width:95%}}
@media (max-width:600px){.w3-modal-content{margin:0 10px;width:auto!important}.w3-modal{padding-top:30px}
.w3-dropdown-hover.w3-mobile .w3-dropdown-content,.w3-dropdown-click.w3-mobile .w3-dropdown-content{position:relative}
.w3-hide-small{display:none!important}.w3-mobile{display:block;width:100%!important}.w3-bar-item.w3-mobile,.w3-dropdown-hover.w3-mobile,.w3-dropdown-click.w3-mobile{text-align:center}
.w3-dropdown-hover.w3-mobile,.w3-dropdown-hover.w3-mobile .w3-btn,.w3-dropdown-hover.w3-mobile .w3-button,.w3-dropdown-click.w3-mobile,.w3-dropdown-click.w3-mobile .w3-btn,.w3-dropdown-click.w3-mobile .w3-button{width:100%}}
@media (max-width:768px){.w3-modal-content{width:500px}.w3-modal{padding-top:50px}}
@media (min-width:993px){.w3-modal-content{width:900px}.w3-hide-large{display:none!important}.w3-sidebar.w3-collapse{display:block!important}}
@media (max-width:992px) and (min-width:601px){.w3-hide-medium{display:none!important}}
@media (max-width:992px){.w3-sidebar.w3-collapse{display:none}.w3-main{margin-left:0!important;margin-right:0!important}.w3-auto{max-width:100%}}
.w3-top,.w3-bottom{position:fixed;width:100%;z-index:1}.w3-top{top:0}.w3-bottom{bottom:0}
.w3-overlay{position:fixed;display:none;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);z-index:2}
.w3-display-topleft{position:absolute;left:0;top:0}.w3-display-topright{position:absolute;right:0;top:0}
.w3-display-bottomleft{position:absolute;left:0;bottom:0}.w3-display-bottomright{position:absolute;right:0;bottom:0}
.w3-display-middle{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%)}
.w3-display-left{position:absolute;top:50%;left:0%;transform:translate(0%,-50%);-ms-transform:translate(-0%,-50%)}
.w3-display-right{position:absolute;top:50%;right:0%;transform:translate(0%,-50%);-ms-transform:translate(0%,-50%)}
.w3-display-topmiddle{position:absolute;left:50%;top:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-bottommiddle{position:absolute;left:50%;bottom:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-container:hover .w3-display-hover{display:block}.w3-display-container:hover span.w3-display-hover{display:inline-block}.w3-display-hover{display:none}
.w3-display-position{position:absolute}
.w3-circle{border-radius:50%}
.w3-round-small{border-radius:2px}.w3-round,.w3-round-medium{border-radius:4px}.w3-round-large{border-radius:8px}.w3-round-xlarge{border-radius:16px}.w3-round-xxlarge{border-radius:32px}
.w3-row-padding,.w3-row-padding>.w3-half,.w3-row-padding>.w3-third,.w3-row-padding>.w3-twothird,.w3-row-padding>.w3-threequarter,.w3-row-padding>.w3-quarter,.w3-row-padding>.w3-col{padding:0 8px}
.w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
.w3-code,.w3-codespan{font-family:Consolas,"courier new";font-size:16px}
.w3-code{width:auto;background-color:#fff;padding:8px 12px;border-left:4px solid #4CAF50;word-wrap:break-word}
.w3-codespan{color:crimson;background-color:#f1f1f1;padding-left:4px;padding-right:4px;font-size:110%}
.w3-card,.w3-card-2{box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)}
.w3-card-4,.w3-hover-shadow:hover{box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)}
.w3-spin{animation:w3-spin 2s infinite linear}@keyframes w3-spin{0%{transform:rotate(0deg)}100%{transform:rotate(359deg)}}
.w3-animate-fading{animation:fading 10s infinite}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;animation:animatetop 0.4s}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.w3-animate-left{position:relative;animation:animateleft 0.4s}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;animation:animateright 0.4s}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;animation:animatebottom 0.4s}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
.w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.w3-opacity,.w3-hover-opacity:hover{opacity:0.60}.w3-opacity-off,.w3-hover-opacity-off:hover{opacity:1}
.w3-opacity-max{opacity:0.25}.w3-opacity-min{opacity:0.75}
.w3-greyscale-max,.w3-grayscale-max,.w3-hover-greyscale:hover,.w3-hover-grayscale:hover{filter:grayscale(100%)}
.w3-greyscale,.w3-grayscale{filter:grayscale(75%)}.w3-greyscale-min,.w3-grayscale-min{filter:grayscale(50%)}
.w3-sepia{filter:sepia(75%)}.w3-sepia-max,.w3-hover-sepia:hover{filter:sepia(100%)}.w3-sepia-min{filter:sepia(50%)}
.w3-tiny{font-size:10px!important}.w3-small{font-size:12px!important}.w3-medium{font-size:15px!important}.w3-large{font-size:18px!important}
.w3-xlarge{font-size:24px!important}.w3-xxlarge{font-size:36px!important}.w3-xxxlarge{font-size:48px!important}.w3-jumbo{font-size:64px!important}
.w3-left-align{text-align:left!important}.w3-right-align{text-align:right!important}.w3-justify{text-align:justify!important}.w3-center{text-align:center!important}
.w3-border-0{border:0!important}.w3-border{border:1px solid #ccc!important}
.w3-border-top{border-top:1px solid #ccc!important}.w3-border-bottom{border-bottom:1px solid #ccc!important}
.w3-border-left{border-left:1px solid #ccc!important}.w3-border-right{border-right:1px solid #ccc!important}
.w3-topbar{border-top:6px solid #ccc!important}.w3-bottombar{border-bottom:6px solid #ccc!important}
.w3-leftbar{border-left:6px solid #ccc!important}.w3-rightbar{border-right:6px solid #ccc!important}
.w3-section,.w3-code{margin-top:16px!important;margin-bottom:16px!important}
.w3-margin{margin:16px!important}.w3-margin-top{margin-top:16px!important}.w3-margin-bottom{margin-bottom:16px!important}
.w3-margin-left{margin-left:16px!important}.w3-margin-right{margin-right:16px!important}
.w3-padding-small{padding:4px 8px!important}.w3-padding{padding:8px 16px!important}.w3-padding-large{padding:12px 24px!important}
.w3-padding-16{padding-top:16px!important;padding-bottom:16px!important}.w3-padding-24{padding-top:24px!important;padding-bottom:24px!important}
.w3-padding-32{padding-top:32px!important;padding-bottom:32px!important}.w3-padding-48{padding-top:48px!important;padding-bottom:48px!important}
.w3-padding-64{padding-top:64px!important;padding-bottom:64px!important}
.w3-left{float:left!important}.w3-right{float:right!important}
.w3-button:hover{color:#000!important;background-color:#ccc!important}
.w3-transparent,.w3-hover-none:hover{background-color:transparent!important}
.w3-hover-none:hover{box-shadow:none!important}


/* Colors */
.w3-amber,.w3-hover-amber:hover{color:#000!important;background-color:#ffc107!important}
.w3-aqua,.w3-hover-aqua:hover{color:#000!important;background-color:#00ffff!important}
.w3-blue,.w3-hover-blue:hover{color:#fff!important;background-color:#2196F3!important}
.w3-light-blue,.w3-hover-light-blue:hover{color:#000!important;background-color:#87CEEB!important}
.w3-brown,.w3-hover-brown:hover{color:#fff!important;background-color:#795548!important}
.w3-cyan,.w3-hover-cyan:hover{color:#000!important;background-color:#00bcd4!important}
.w3-blue-grey,.w3-hover-blue-grey:hover,.w3-blue-gray,.w3-hover-blue-gray:hover{color:#fff!important;background-color:#607d8b!important}
.w3-green,.w3-hover-green:hover{color:#fff!important;background-color:#4CAF50!important}
.w3-light-green,.w3-hover-light-green:hover{color:#000!important;background-color:#8bc34a!important}
.w3-indigo,.w3-hover-indigo:hover{color:#fff!important;background-color:#3f51b5!important}
.w3-khaki,.w3-hover-khaki:hover{color:#000!important;background-color:#f0e68c!important}
.w3-lime,.w3-hover-lime:hover{color:#000!important;background-color:#cddc39!important}
.w3-orange,.w3-hover-orange:hover{color:#000!important;background-color:#ff9800!important}
.w3-deep-orange,.w3-hover-deep-orange:hover{color:#fff!important;background-color:#ff5722!important}
.w3-pink,.w3-hover-pink:hover{color:#fff!important;background-color:#e91e63!important}
.w3-purple,.w3-hover-purple:hover{color:#fff!important;background-color:#9c27b0!important}
.w3-deep-purple,.w3-hover-deep-purple:hover{color:#fff!important;background-color:#673ab7!important}
.w3-red,.w3-hover-red:hover{color:#fff!important;background-color:#f44336!important}
.w3-sand,.w3-hover-sand:hover{color:#000!important;background-color:#fdf5e6!important}
.w3-teal,.w3-hover-teal:hover{color:#fff!important;background-color:#009688!important}
.w3-yellow,.w3-hover-yellow:hover{color:#000!important;background-color:#ffeb3b!important}
.w3-white,.w3-hover-white:hover{color:#000!important;background-color:#fff!important}
.w3-black,.w3-hover-black:hover{color:#fff!important;background-color:#000!important}
.w3-grey,.w3-hover-grey:hover,.w3-gray,.w3-hover-gray:hover{color:#000!important;background-color:#9e9e9e!important}
.w3-light-grey,.w3-hover-light-grey:hover,.w3-light-gray,.w3-hover-light-gray:hover{color:#000!important;background-color:#f1f1f1!important}
.w3-dark-grey,.w3-hover-dark-grey:hover,.w3-dark-gray,.w3-hover-dark-gray:hover{color:#fff!important;background-color:#616161!important}
.w3-pale-red,.w3-hover-pale-red:hover{color:#000!important;background-color:#ffdddd!important}
.w3-pale-green,.w3-hover-pale-green:hover{color:#000!important;background-color:#ddffdd!important}
.w3-pale-yellow,.w3-hover-pale-yellow:hover{color:#000!important;background-color:#ffffcc!important}
.w3-pale-blue,.w3-hover-pale-blue:hover{color:#000!important;background-color:#ddffff!important}
.w3-text-amber,.w3-hover-text-amber:hover{color:#ffc107!important}
.w3-text-aqua,.w3-hover-text-aqua:hover{color:#00ffff!important}
.w3-text-blue,.w3-hover-text-blue:hover{color:#2196F3!important}
.w3-text-light-blue,.w3-hover-text-light-blue:hover{color:#87CEEB!important}
.w3-text-brown,.w3-hover-text-brown:hover{color:#795548!important}
.w3-text-cyan,.w3-hover-text-cyan:hover{color:#00bcd4!important}
.w3-text-blue-grey,.w3-hover-text-blue-grey:hover,.w3-text-blue-gray,.w3-hover-text-blue-gray:hover{color:#607d8b!important}
.w3-text-green,.w3-hover-text-green:hover{color:#4CAF50!important}
.w3-text-light-green,.w3-hover-text-light-green:hover{color:#8bc34a!important}
.w3-text-indigo,.w3-hover-text-indigo:hover{color:#3f51b5!important}
.w3-text-khaki,.w3-hover-text-khaki:hover{color:#b4aa50!important}
.w3-text-lime,.w3-hover-text-lime:hover{color:#cddc39!important}
.w3-text-orange,.w3-hover-text-orange:hover{color:#ff9800!important}
.w3-text-deep-orange,.w3-hover-text-deep-orange:hover{color:#ff5722!important}
.w3-text-pink,.w3-hover-text-pink:hover{color:#e91e63!important}
.w3-text-purple,.w3-hover-text-purple:hover{color:#9c27b0!important}
.w3-text-deep-purple,.w3-hover-text-deep-purple:hover{color:#673ab7!important}
.w3-text-red,.w3-hover-text-red:hover{color:#f44336!important}
.w3-text-sand,.w3-hover-text-sand:hover{color:#fdf5e6!important}
.w3-text-teal,.w3-hover-text-teal:hover{color:#009688!important}
.w3-text-yellow,.w3-hover-text-yellow:hover{color:#d2be0e!important}
.w3-text-white,.w3-hover-text-white:hover{color:#fff!important}
.w3-text-black,.w3-hover-text-black:hover{color:#000!important}
.w3-text-grey,.w3-hover-text-grey:hover,.w3-text-gray,.w3-hover-text-gray:hover{color:#757575!important}
.w3-text-light-grey,.w3-hover-text-light-grey:hover,.w3-text-light-gray,.w3-hover-text-light-gray:hover{color:#f1f1f1!important}
.w3-text-dark-grey,.w3-hover-text-dark-grey:hover,.w3-text-dark-gray,.w3-hover-text-dark-gray:hover{color:#3a3a3a!important}
.w3-border-amber,.w3-hover-border-amber:hover{border-color:#ffc107!important}
.w3-border-aqua,.w3-hover-border-aqua:hover{border-color:#00ffff!important}
.w3-border-blue,.w3-hover-border-blue:hover{border-color:#2196F3!important}
.w3-border-light-blue,.w3-hover-border-light-blue:hover{border-color:#87CEEB!important}
.w3-border-brown,.w3-hover-border-brown:hover{border-color:#795548!important}
.w3-border-cyan,.w3-hover-border-cyan:hover{border-color:#00bcd4!important}
.w3-border-blue-grey,.w3-hover-border-blue-grey:hover,.w3-border-blue-gray,.w3-hover-border-blue-gray:hover{border-color:#607d8b!important}
.w3-border-green,.w3-hover-border-green:hover{border-color:#4CAF50!important}
.w3-border-light-green,.w3-hover-border-light-green:hover{border-color:#8bc34a!important}
.w3-border-indigo,.w3-hover-border-indigo:hover{border-color:#3f51b5!important}
.w3-border-khaki,.w3-hover-border-khaki:hover{border-color:#f0e68c!important}
.w3-border-lime,.w3-hover-border-lime:hover{border-color:#cddc39!important}
.w3-border-orange,.w3-hover-border-orange:hover{border-color:#ff9800!important}
.w3-border-deep-orange,.w3-hover-border-deep-orange:hover{border-color:#ff5722!important}
.w3-border-pink,.w3-hover-border-pink:hover{border-color:#e91e63!important}
.w3-border-purple,.w3-hover-border-purple:hover{border-color:#9c27b0!important}
.w3-border-deep-purple,.w3-hover-border-deep-purple:hover{border-color:#673ab7!important}
.w3-border-red,.w3-hover-border-red:hover{border-color:#f44336!important}
.w3-border-sand,.w3-hover-border-sand:hover{border-color:#fdf5e6!important}
.w3-border-teal,.w3-hover-border-teal:hover{border-color:#009688!important}
.w3-border-yellow,.w3-hover-border-yellow:hover{border-color:#ffeb3b!important}
.w3-border-white,.w3-hover-border-white:hover{border-color:#fff!important}
.w3-border-black,.w3-hover-border-black:hover{border-color:#000!important}
.w3-border-grey,.w3-hover-border-grey:hover,.w3-border-gray,.w3-hover-border-gray:hover{border-color:#9e9e9e!important}
.w3-border-light-grey,.w3-hover-border-light-grey:hover,.w3-border-light-gray,.w3-hover-border-light-gray:hover{border-color:#f1f1f1!important}
.w3-border-dark-grey,.w3-hover-border-dark-grey:hover,.w3-border-dark-gray,.w3-hover-border-dark-gray:hover{border-color:#616161!important}
.w3-border-pale-red,.w3-hover-border-pale-red:hover{border-color:#ffe7e7!important}.w3-border-pale-green,.w3-hover-border-pale-green:hover{border-color:#e7ffe7!important}
.w3-border-pale-yellow,.w3-hover-border-pale-yellow:hover{border-color:#ffffcc!important}.w3-border-pale-blue,.w3-hover-border-pale-blue:hover{border-color:#e7ffff!important}

p{
  font-size: 125%;
}
a:hover {
   color: #000000;
}
.link:hover{
  color: #000000;
}
.justified-text{
  text-align: justify;
}

.full-screen-container{

  min-width: 100%;
  max-width: 100%;
}
.header{
  min-height:15vh;
  position: fixed;
  background-color: #fcf1ed;
}
.red-text{
  color:red;
}

.red-text-hover:hover{
  color:red;
  text-decoration: none!important;
}

.side-menu{
  position:fixed;
  width:25%;
  height:101%;
  background-color: #fcf1ed;
  margin-top:-19vh;
  z-index: 6;
  border-right: 1.2px solid black;
  overflow-y: scroll;
}
.side-menu-mobile{
  position:fixed;
  width:70%;
  height:101%;
  background-color: #fcf1ed;
  margin-top:-24vh;
  margin-left: -2.5vw;
  z-index: 6;
  border-right: 3px solid black;
  overflow-y: scroll;
}
.side-menu-family{
  font-size: 1.4vw;
  cursor:pointer;
}
.side-menu-family-mobile{
  font-size: 4vw;
  cursor:pointer;
}
.side-menu-family:hover{
  font-size: 1.4vw;
  cursor:pointer;
  color:red;

}

.caption{
  opacity:0.6;
  font-size: 1vw;
  text-align: center;
}

.caption-mobile{
  opacity:0.6;
  font-size: 2.2vw;
  text-align: center;
  max-width:80vw;
}

.blurb{
  font-size:1.3vw;
  line-height:1.8vmax;
  margin-top:4vh;
  text-align: justify;
}
.blurb-mobile{
  font-size:3.6vw;
  line-height:2.8vmax;
  margin-top:4vh;
  text-align: justify;
}
.blurb-col{
  min-width:28vw;
  max-width:28vw;
}
.blurb-col-wide{
  min-width:40vw;
  max-width:40vw;
}
.blurb-col-wide-mobile{
  min-width:80vw;
  max-width:80vw;
}
.right-aligned-children{
  text-align: right!important;
}

hr.underline_mobile {
  border: 1px solid red;
  width:40vw;
  margin-bottom:10vh;
}
hr.menu-underline_mobile {
  border: 1px solid red;
  width:60vw;
  margin-bottom:10vh;
}
.bold-text{
  font-weight: bold!important;
}
.bio-p-mobile{
font-size:4.5vw;line-height:4.3vh;
}
.page_text{
  margin-left:20vw;
  margin-right:20vw;
  text-align:justify;
}
.page-text-mobile{
  margin-left:10vw;
  margin-right:10vw;
  text-align:justify;

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





@media only screen
(orientation : portrait) {
  .page_text_mobile{
    margin-left:5vw;
    margin-right:5vw;
    text-align:left!important;
  }




}

@media only screen
(orientation : landscape) {

  .bio_p{
  font-size:1.4vw;line-height:2.3vw;
  }

  hr.underline {
    border: 1px solid red;
    width:10vw;
    margin-left:20vw;
    margin-bottom:10vh;

  }
}




.standard_space{
  height:15vh;
  /* background-color: red; */
}
  .sidenav-icon-fixed {
  transition: 0.5s ease;



  z-index: 5;
  /* background: #eee; */
  overflow-x: hidden;

  /* padding: 2px 0; */
  }
  /* .link:hover{
    color:red;
  } */
  /* a:hover{
    color:red;
  } */

  .title {
    top: 0;
    width: 100%;
    height: 32vh;
    align-items: center;
    align-self: center;
    align-content: center;
    text-align: center;
    font-size: 5vw;
  background-color: #fcf1ed;
  color: black;
  }
  .mobile_title {
    top: 0;
    width: 100%;
    height: 32vw;
    align-items: center;
    align-self: center;
    align-content: center;
    text-align: center;
    font-size: 5vw;
  background-color: #fcf1ed;
  color: black;
  }




    .menu{
      opacity: 0.2;
        transition: opacity 550ms ease-in;

    }
    .menu:hover{
      opacity: 1;
    }
    .footer{
      margin-left: 45vw;
      border-radius: 50% 50% 0% 0%;
      float:bottom;
      width:10vw;
      height:5vh;
      bottom:0;
      opacity: 0.2;
      z-index:99;
      position:fixed;
      transition: opacity 550ms ease-in;
      -webkit-transition: opacity 550ms ease-in;
      -moz-transition: opacity 550ms ease-in;
      -o-transition: opacity 550ms ease-in;
    }
    .footer:hover{
      border-radius: 20% 20% 0% 0%;
      transition: 550ms ease-in;
      -webkit-transition: 550ms ease-in;
      -moz-transition: 550ms ease-in;
      -o-transition: 550ms ease-in;
      height:12vh;
      opacity: 1;
    }





* {
 font-family: "Times New Roman", Times, serif;
}
html, body, h1, h2, h3, h4, h5, h6{
  font-family: "Times New Roman", Times, serif;

}

html.smooth-scroll-section{
  scroll-behavior: smooth!important;
}
.caption:hover{
    /* color: red; */
    color:black!important;
    cursor: pointer;
}
.family_from_sidebar{
  text-align: center;
}
.nav__link--btn {

transition: background 250ms ease-in-out;
-webkit-transition: background 550ms ease-in;
-moz-transition: background  550ms ease-in;
-o-transition: background  550ms ease-in;
width:25vw;
height:5vmax;
font-size:1.6vmax;
font:serif;
font-weight: 100;
background-color:  #fcf1ed;

color: black;
cursor: pointer;
align-items: center;
text-align: center;
display: inline-block;

}


.sidenav {
  width: 25vw;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 98;
  /* top: 10vh; */
  overflow-x:hidden;
  box-shadow: 1px 0px 1px grey;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;
  -o-transition: 0.5s ease;
  /* background-color: white; */
  /* transition:display 550ms ease-in-out; */

}
.on_section{
  background-color: #fcf1ed;
  color: red;
}

.nav__link--btn:hover {
  width:25vw;
  height:5vmax;
  /* display: inline-flex; */
  align-items: center;
  display: inline-block;
  font-size:1.6vmax;
  color:red!important;

}
/* .nav__link--btn::after {
display: none;
} */

/* .nav__link--btn--highlight {
background: limegreen;
border-color: limegreen;
color: #333;
} */

/* .nav__link--btn--highlight:hover {
background: var(--text);
border-color: var(--text);
}

.nav-scrolled {
--text: #333;
--text-inverse: #f4f4f4;
--background: #f4f4f4;
box-shadow: 0 3px 20px rgba(0, 0, 0, 0.2);
} */


html {
    /* For desktop */
    /* overflow: hidden; */
}
body {
    overflow: scroll;
    width:100vw;

    background-color: #fcf1ed;
}



.responsive{
  display: flex;
  height:auto;
}

.black_text{
  color:black!important;
}
.scroll{
  overflow: scroll;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  height: 15vh;
  align-items: center;
  align-self: center;
  align-content: center;
  text-align: center;
  padding: 10px 16px;
  font-size: 5vw;
background: white;
color: black;
}




.margin-left-1{
margin-left: 1vw;
}
.margin-top-1{
margin-top: 1vh;
}
.margin-right-1{
margin-right: 1vw;
}
.margin-bottom-1{
margin-bottom: 1vh;
}

.margin-bottom-2{
margin-bottom: 2vh;
}
.margin-left-2{
margin-left: 2vw;
}
.margin-left-5{
margin-left: 5vw;
}
.margin-top-5{
margin-top: 5vh;
}
.margin-right-5{
margin-right: 5vw;
}
.margin-bottom-5{
margin-bottom: 5vh;
}

.margin-left-10{
margin-left: 10vw;
}
.margin-top-10{
margin-top: 10vh;
}
.margin-right-10{
margin-right: 10vw;
}
.margin-bottom-10{
margin-bottom: 10vh;
}

.margin-left-15{
margin-left: 15vw;
}
.margin-top-15{
margin-top: 15vh;
}
.margin-right-15{
margin-right: 15vw;
}
.margin-bottom-15{
margin-bottom: 15vh;
}

.margin-left-20{
margin-left: 20vw;
}
.margin-top-20{
margin-top: 20vh;
}
.margin-right-20{
margin-right: 20vw;
}
.margin-bottom-20{
margin-bottom: 20vh;
}
.family-name-title{
  font-size:0.8vw;
  text-align: right;
  margin-right:2vw;
}

.nav__list {
list-style: none;
margin: 0;
padding: 0;
display: flex;
}

.nav__link {
--spacing: 1em;
text-decoration: none;
color: inherit;
display: inline-block;
padding: calc(var(--spacing) / 2) var(--spacing);
position: relative;
text-transform: uppercase;
letter-spacing: 2px;
font-size: 0.9rem;
}

.nav__link:after {
content: "";
position: absolute;
bottom: 0;
left: var(--spacing);
right: var(--spacing);
height: 2px;
background: currentColor;
-webkit-transform: scaleX(0);
transform: scaleX(0);
transition: -webkit-transform 150ms ease-in-out;
transition: transform 150ms ease-in-out;
-webkit-transform :150ms ease-in-out;


}

.nav__link:hover::after {
-webkit-transform: scaleX(1);
transform: scaleX(1);
}


/* This styles each family in the nav bar */
.home-intro {
padding: 50vh 0;
background: #123 url(https://unsplash.it/900);
background-size: cover;
background-blend-mode: multiply;
/* color: white; */
text-align: center;
}

.home-about {
padding: 4em 0;
max-width: 900px;
margin: 0 auto;
}

.columns {
display: flex;
}



.fade-in {
opacity: 0;
transition: opacity 950ms ease-in;
-webkit-transition: opacity 950ms ease-in;
-moz-transition: opacity 950ms ease-in;
-o-transition: opacity 950ms ease-in;
}
.appear {
opacity: 1;
transition: opacity 950ms ease-in;
-webkit-transition: opacity 950ms ease-in;
-moz-transition: opacity 950ms ease-in;
-o-transition: opacity 950ms ease-in;
}

.fade-in.appear {
opacity: 1;
}

.more-stuff-grid {
background: #f4f4f4;
padding: 4em 0;
display: grid;
grid-gap: 2em;
align-items: center;
grid-template-columns: minmax(1em, 1fr) repeat(2, minmax(200px, 400px)) minmax(
    1em,
    1fr
  );
}

.from-left {
grid-column: 2 / 3;
-webkit-transform: translateX(-50%);
transform: translateX(-50%);
}

.from-right {
grid-column: 3 / 4;
-webkit-transform: translateX(50%);
transform: translateX(50%);
}

.from-left,
.from-right {
transition: opacity 250ms ease-in, -webkit-transform 400ms ease-in;
transition: opacity 250ms ease-in, transform 400ms ease-in;
transition: opacity 250ms ease-in, transform 400ms ease-in,
  -webkit-transform 400ms ease-in;
opacity: 0;
}

.from-left.appear,
.from-right.appear {
-webkit-transform: translateX(0);
transform: translateX(0);
opacity: 1;
}

.sidenav-icon {
transition: 0.5s ease;
-webkit-transition: 0.5s ease;
-moz-transition: 0.5s ease;
-o-transition: 0.5s ease;
position: fixed;
z-index: 1;
/* background: #eee; */
overflow-x: hidden;

/* padding: 2px 0; */
}
/*
.sidenav a {
padding: 6px 6px 6px 20px;
margin-left: 2vw;
margin-top: 0.82vw;
text-decoration: none;
font-size: 0.9vw;
color: #2196F3;
display: block;
}

.sidenav a:hover {
color: #064579;
} */

.main {
  width:82vw;

}

@media screen and (max-height: 450px) {
.sidenav {padding-top: 15px;}
.sidenav {font-size: 18px;}
}


@media only screen
and (orientation : portrait) {
  .page_text_mobile{
    margin-left:5vw;
    margin-right:5vw;
    text-align:left!important;
  }



}

@media only screen
and (orientation : landscape) {

  .bio_p{
  font-size:1.4vw;line-height:2.3vw;

  }

  hr.underline {
    border: 1px solid red;
    width:10vw;

    margin-bottom:10vh;

  }

    .bio_p_about{
      font-size:1.4vw;
      line-height:2.3vw;
      width:65vw;
      margin-left:-7.5vw;

    }
}

@media only screen
and (orientation : portrait) {
  .page_text_mobile{
    margin-left:5vw;
    margin-right:5vw;
    text-align:left!important;
  }




}

@media only screen
and (orientation : landscape) {

  .bio_p{
  font-size:1.4vw;line-height:2.3vw;
  }

  hr.underline {
    border: 1px solid red;
    width:10vw;

    margin-bottom:10vh;

  }

    .bio_p_about{
      font-size:1.4vw;
      line-height:2.3vw;
      width:65vw;
      margin-left:-7.5vw;

    }
}
